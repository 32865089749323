import React, { useEffect, useRef, useState } from "react";
import Isotope from "isotope-layout";
import ProjectDetailsModal from "./ProjectDetailsModal";
const Portfolio = () => {
  // init one ref to store the future isotope object
  const isotope = useRef();
  // store the filter keyword in a state
  const [filterKey, setFilterKey] = useState("*");
  const [imagesLoaded, setimagesLoaded] = useState(0);
  const [selectedProjectDetails, setSelectedProjectDetails] = useState();
  const [isOpen, setIsOpen] = useState(false);

  const htmlElement = document.getElementsByTagName("html")[0];
  const isRtl = htmlElement.getAttribute("dir") === "rtl";

  const filters = {
    // DETAILED: "Details",
    // MOCKUPS: "Mockups",
    // YOUTUBE: "Youtube Videos",
    // VIMEO: "Vimeo Videos",
  };

  const types = {
    IMAGE: "image",
    VIDEO: "video",
    DOCUMENT: "document",
  };

  const projectsData = [
    {
      title: "Components",
      type: types.DOCUMENT,
      document: {
        projectInfo1:
          `I am working on a personal project to collect and share programming components. Right now, the platform has a small collection of UI components, and users can also upload and share their own. The project is still in the early stages, but the goal is to grow it into a large library of both UI and backend components.` ,
        projectInfo2: `The platform is free to use, and the vision is to create a community where developers can easily find, share, and contribute components to help each other build better projects.`,
        technologies: "Typescript, React, Node, express, microservices with moleculer, mysql, digital ocean",
        url1: {
          name: "Components",
          link: "https://components.lingash.me/",
        },
      },
      
      thumbImage: "images/projects/components-logo.png",

      categories: [filters.DETAILED],
    },
    {
      title: "mmGuitar",
      type: types.DOCUMENT,
      document: {
        projectInfo1:
          `This app is made for music lovers who want to explore and share their passion for songs. It lets users find song lyrics, chords, and images all in one place. The app also encourages community involvement, allowing users to request new songs, add their own content, and improve existing ones.
            `,
        projectInfo2: `Users can edit chords to fit their preferences, making it easier to play along with their favorite songs. 
          They can also rate songs, report mistakes, and help keep the content high quality. This app is all about creating a friendly space where people who love music can connect, share, and grow together. `,
        projectInfo3: "I'm responsible for whole frontend.",
        client: "Studio AMK Co.,Ltd",
        technologies: "Typescript, React, capacitor js, Java, Android Studio",
        date: "Dec, 2024",
        url1: {
          name: "mmGuitar",
          link: "https://play.google.com/store/apps/details?id=com.studioamk.guitar",
        },

        // sliderImages: [
        //   "images/projects/project-2.jpg",
        //   "images/projects/project-5.jpg",
        // ],
      },

      thumbImage: "images/projects/mmGuitar.png",

      categories: [filters.DETAILED],
    },
    {
      title: "Safe",
      type: types.DOCUMENT,
      document: {
        projectInfo1:
          `This is also my personal project. ` ,
        projectInfo2:
        `Developed a secure and user-friendly platform for storing key-value pairs, such as passwords. What sets this project apart is its simplicity and accessibility: users can effortlessly create accounts without relying on third-party services. The platform generates memorable user IDs, making it easy for users to access their data from anywhere.`,
        technologies: "Typescript, React, Node, express, microservices with moleculer, mysql, digital ocean",
        url1: {
          name: "Safe",
          link: "https://safe.lingash.me/",
        },
      },
      
      thumbImage: "images/projects/vaultpair.webp",

      categories: [filters.DETAILED],
    },
    {
      title: "Thai and Malaysia Payment",
      type: types.DOCUMENT,
      document: {
        projectInfo1:
          `Connected Razer Gold Thai and Malaysia payment channels to the Ywar Payment platform, facilitating transactions between content providers and available payment methods in Myanmar. 
          Successfully implemented new Ywar payment channels for Shan Game, the first multiplayer game in Myanmar. Held responsibility for both frontend and backend development. 
          Currently, Thai payment channels are live, while Malaysian payment channels are not yet active
            `,
        projectInfo2: "To view the Thai payment methods, please access the Shan Game shop using a Thai IP address. Otherwise it will only show myanmar payment methods",
        projectInfo3: "Both Ywar payment platform and Shan game are products of Studio AMK Co.,Ltd",
        client: "Studio AMK Co.,Ltd",
        technologies: "Js,EJS,Node, Moleculer Microservice, express, mysql",
        date: "Jan, 2023",
        url1: {
          name: "Shan Game",
          link: "https://shankoemee.com/",
        },

        // sliderImages: [
        //   "images/projects/project-2.jpg",
        //   "images/projects/project-5.jpg",
        // ],
      },

      thumbImage: "images/projects/razer.png",

      categories: [filters.DETAILED],
    },
    {
      title: "POS(Point of Sale) Softwares",
      type: types.DOCUMENT,
      document: {
        projectInfo1:
          `Developed three POS projects with a team as a freelance software developer. Took charge of developing backend logic, designing the database structure, and implementing server functionality. Developed software features included stock management, sale and purchase management, and employee management. The administration has the capability to handle these operations through the POS terminal, while employees can utilize the sale terminal to place orders and process product checkouts.` ,
        technologies: "Node, express,microservices with moleculer, react, mui, mysql, digital ocean, pm2, nginx ",
      },

      thumbImage: "images/projects/pos.webp",

      categories: [filters.DETAILED],
    },
    {
      title: "334 adventures Game",
      type: types.DOCUMENT,
      document: {
        projectInfo1:
          `This project represents my first venture into game development, where I developed an educational game specifically targeted towards babies aged around 5 years old. The game's primary objective is to provide a fun and interactive learning experience, helping young children to develop essential cognitive skills such as sorting and object finding`,
        projectInfo2: 
          `The game comprises 15 engaging stages, each thoughtfully crafted to captivate our little players while simultaneously promoting their cognitive development. Within the project, I took charge of creating 5 of these stages, infusing them with educational content to encourage a positive learning experience.`,
        projectInfo3: 
          `In addition to the engaging gameplay, I also implemented essential features like parental control to ensure a safe and secure gaming environment for our young audience.To bring this vision to life, I utilized the powerful and versatile Cocos Creator engine`,
        client: "Studio AMK Co.,Ltd",
        technologies: "Cocos Creator 2D,Typescript",
        date: "April, 2023",
        url1: {
          name: "334's Adventures",
          link: "https://play.google.com/store/apps/details?id=com.baby334.adventures",
        },
      },

      thumbImage: "images/projects/334.webp",

      categories: [filters.DETAILED],
    },
    {
      title: "Static Websites",
      type: types.DOCUMENT,
      document: {
        projectInfo1:
          `Responsible for creating static websites for client.`,
        client: "Studio AMK Co.,Ltd",
        technologies: "Html, Css and Js",
        url1: {
          name: "Tak Business",
          link: "https://www.takbusiness.net",
        },
        url2: {
          name: "Studio Amk",
          link: "https://studioamk.com",
        },
      },

      thumbImage: "images/projects/static.jpg",

      categories: [filters.DETAILED],
    },
    {
      title: "Shan Game",
      type: types.DOCUMENT,
      document: {
        projectInfo1:
          `Maintained and improved Shan Ko Mee game, which is Myanmar's first multiplayer game, working on both frontend and backend. Responsible for creating the promotion that is 2d mini world game where player can wonder in the world and do daily quest and get reward. That promotion runs for ten days during Thadingyut Festival.`,
        client: "Studio AMK Co.,Ltd",
        technologies: "Cocos Creator 2D,Typescript",
        url1: {
          name: "Shan Game",
          link: "https://shankoemee.com/",
        },
      },

      thumbImage: "images/projects/shan.png",

      categories: [filters.DETAILED],
    },
    {
      title: "Autheo Web3",
      type: types.DOCUMENT,
      document: {
        projectInfo1:
          `I was responsible for the frontend. Autheo is a product of Launch Lengends. Autheo empowers visionary creators and influencers to unlock full potential. The layer-1 blockchain and ecosystem drive exponential growth, connecting entrepreneurs with innovators and experts, unlocking limitless opportunities for collaboration and success.` ,
        technologies: "Typescript, React, Next",
        url1: {
          name: "Autheo",
          link: "https://autheo.com/",
        },
      },
      
      thumbImage: "images/projects/launchLegend.jpeg",

      categories: [filters.DETAILED],
    },
    {
      title: "Smash",
      type: types.DOCUMENT,
      document: {
        projectInfo1:
          `A fun game I created for myself to enjoy`,
        technologies: "Cocos Creator",
        url1: {
          name: "Smash",
          link: "https://test.lingash.me/",
        },
      },

      thumbImage: "images/projects/cocos.jpeg",
      categories: [filters.DETAILED],
    },
    // {
    //   title: "Mockups Design 1",
    //   type: types.IMAGE,

    //   thumbImage: "images/projects/project-2.jpg",

    //   categories: [filters.MOCKUPS],
    // },
    // {
    //   title: "YouTube Video",
    //   type: types.VIDEO,
    //   video: {
    //     vimeo: false,
    //     id: "PMNnEEEacCg",
    //   },
    //   thumbImage: "images/projects/project-3.jpg",

    //   categories: [filters.YOUTUBE],
    // },
    // {
    //   title: "Vimeo Video",
    //   type: types.VIDEO,
    //   video: {
    //     vimeo: true,
    //     id: "259411563",
    //   },

    //   thumbImage: "images/projects/project-4.jpg",
    //   categories: [filters.VIMEO],
    // },
    // {
    //   title: "Detailed Project 2",
    //   type: types.DOCUMENT,
    //   document: {
    //     projectInfo:
    //       "Quidam lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure. Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
    //     client: "Ruby Clinton",
    //     technologies: "iOS, HTML5, CSS3, PHP, Java",
    //     industry: "Art & Design",
    //     date: "July 16, 2019",
    //     url: {
    //       name: "www.example.com",
    //       link: "https://www.example.com",
    //     },
    //     sliderImages: [
    //       "images/projects/project-1.jpg",
    //       "images/projects/project-2.jpg",
    //     ],
    //   },
    //   thumbImage: "images/projects/project-5.jpg",
    //   categories: [filters.DETAILED],
    // },
    // {
    //   title: "Mockups Design 2",
    //   type: types.IMAGE,

    //   thumbImage: "images/projects/project-6.jpg",

    //   categories: [filters.MOCKUPS],
    // },
  ];

  // initialize an Isotope object with configs
  useEffect(() => {
    isotope.current = new Isotope(".portfolio-filter", {
      itemSelector: ".filter-item",
      layoutMode: "masonry",
      originLeft: !isRtl,
    });

    // cleanup
    return () => {
      isotope.current.destroy();
    };
  }, []);

  // handling filter key change
  useEffect(() => {
    if (imagesLoaded) {
      filterKey === "*"
        ? isotope.current.arrange({ filter: `*` })
        : isotope.current.arrange({ filter: `.${filterKey}` });
    }
  }, [filterKey, imagesLoaded]);

  const handleFilterKeyChange = (key) => () => setFilterKey(key);

  const getKeyByValue = (value) => {
    return Object.keys(filters).find((key) => filters[key] === value);
  };

  const getFilterClasses = (categories) => {
    if (categories.length > 0) {
      let tempArray = [];
      categories.forEach((category, index) => {
        tempArray.push(getKeyByValue(category));
      });
      return tempArray.join(" ");
    }
  };

  return (
    <>
      <section id="portfolio" className={"section bg-light"}>
        <div className={"container"}>
          {/* Heading */}
          <p className="text-center mb-2 wow fadeInUp">
            <span className="bg-primary text-dark px-2">Portfolio</span>
          </p>
          <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
            Some of my most recent projects
          </h2>
          {/* Heading end*/}
          {/* Filter Menu */}
          <ul
            className={
              "portfolio-menu nav nav-tabs fw-600 justify-content-start justify-content-md-center border-bottom-0 mb-5 wow fadeInUp"
            }
          >
            <li className="nav-item">
              <button
                className={"nav-link " + (filterKey === "*" ? "active" : "")}
                onClick={handleFilterKeyChange("*")}
              >
                All
              </button>
            </li>
            {Object.keys(filters).map((oneKey, i) => (
              <li className="nav-item" key={i}>
                <button
                  className={
                    "nav-link " + (filterKey === oneKey ? "active" : "")
                  }
                  onClick={handleFilterKeyChange(oneKey)}
                >
                  {filters[oneKey]}
                </button>
              </li>
            ))}
          </ul>
          {/* Filter Menu end */}
          <div className="portfolio wow fadeInUp">
            <div className="row portfolio-filter filter-container g-4">
              {projectsData.length > 0 &&
                projectsData.map((project, index) => (
                  <div
                    className={
                      "col-sm-6 col-lg-4 filter-item " +
                      getFilterClasses(project.categories)
                    }
                    key={index}
                  >
                    <div className="portfolio-box">
                      <div className="portfolio-img">
                        <img
                          onLoad={() => {
                            setimagesLoaded(imagesLoaded + 1);
                          }}
                          className="img-fluid d-block portfolio-image"
                          src={project.thumbImage}
                          alt=""
                        />
                        <div
                          className="portfolio-overlay"
                          onClick={() => {
                            setSelectedProjectDetails(projectsData[index]);
                            setIsOpen(true);
                          }}
                        >
                          <button className="popup-ajax stretched-link border-0 p-0 ">
                            {" "}
                          </button>
                          <div className="portfolio-overlay-details">
                            <p className="text-primary text-8">
                              {project.type === types.DOCUMENT && (
                                <i className="fas fa-file-alt"></i>
                              )}
                              {project.type === types.IMAGE && (
                                <i className="fas fa-image"></i>
                              )}
                              {project.type === types.VIDEO && (
                                <i className="fas fa-video"></i>
                              )}
                            </p>
                            <h5 className="text-white text-5">
                              {project?.title}
                            </h5>
                            <span className="text-light">Category</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>
      {/* Modal */}
      {isOpen && (
        <ProjectDetailsModal
          projectDetails={selectedProjectDetails}
          setIsOpen={setIsOpen}
        ></ProjectDetailsModal>
      )}
    </>
  );
};

export default Portfolio;
